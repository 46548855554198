import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { Card, CardHeader, Grid } from "@material-ui/core";
import { useStyles } from "./style";
import clsx from "clsx";
import { useEnqueueSnackbar } from "../../../../hooks/useEnqueueSnackbar";
import useHasPermissions from "../../../../hooks/useHasPermissions";
import TicketValidations from "../../../TicketComponents/TicketValidations";
import CodeReader from "../../../CodeReader";
import { ToggleButton } from "@material-ui/lab";
import CardContent from "@material-ui/core/CardContent";
import ValidationService from "../../../../services/ValidationAccountService";
import apiClient from "../../../../auth/apiClient";
import SelectableChip from "../../../SelectableChip";
import { TICKET_SEARCH_TYPE } from "../../../../constants";
import { useFeatureFlag } from "../../../../hooks/useFeatureFlags";

const validationService = new ValidationService(apiClient);
const ticketRegex =
  "^TicketID:[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$";
const urlRegex =
  "^(https?://)?([A-Za-z-.]){1}(:[0-9]{2,5})?.*/mobilepay/[0-9A-Fa-f]{8}[-]?(?:[0-9A-Fa-f]{4}[-]?){3}[0-9A-Fa-f]{12}";
const guidRegex =
  "[({]?[a-fA-F0-9]{8}[-]?([a-fA-F0-9]{4}[-]?){3}[a-fA-F0-9]{12}[})]?";

const ApplyValidationsPanel = ({
  facilityID, 
  contractHolderIDs,
  autoApplyValidationOffer,
  className,
  scopeAwareFacilityID,
  automaticallyApplyValidationEnabled
}) => {
  const classes = useStyles(); 
  const enqueueSnackbar = useEnqueueSnackbar();
  const [searchInput, setSearchInput] = useState();
  const [isScanning, setIsScanning] = useState(false);
  const [validationOffers, setValidationOffers] = useState([]);
  const { hasPermissions } = useHasPermissions();
  const validateTicketsPermission = hasPermissions(["validatetickets"]);
  const [searchType, setSearchType] = useState(TICKET_SEARCH_TYPE.TICKET);
  const isLPREnabled = useFeatureFlag('LPR');
  const [clearInputField, setClearInputField] = useState(false);

  const [isSendApplyValidationRunning, setIsSendApplyValidationRunning] = useState(false);
  const onSendApplyValidationRunningChange = (value) => setIsSendApplyValidationRunning(value);
  
  const [parsedFromCamera, setParsedFromCamera] = useState(false);
  const onParsedFromCameraChange = (value) => setParsedFromCamera(value);

  const handleQRCodeRead = readResult => {
    if (!readResult || readResult === "") return;
    // first determine if the encoded value matches are predefined formats
    const tests = [new RegExp(ticketRegex, "i"), new RegExp(urlRegex, "i")];
    const validRegexers = tests.filter(regexer => regexer.test(readResult));
    const regexer = validRegexers[0];
    if (!regexer) return;
    // snag the ticket id
    const ticketParseResult = new RegExp(guidRegex, "g").exec(readResult);
    if (!ticketParseResult) return;
    setParsedFromCamera(true);
    setSearchInput(ticketParseResult[0]);
    // Need to clear out search input in order
    // to accept ticket scanned twice
    setTimeout(() => {
      setSearchInput(null);
    }, 200);
  };  

  useEffect(() => {
    if (contractHolderIDs && facilityID) getValidationOffers();
  }, [facilityID]);

  const getValidationOffers = useCallback(async () => {
    try {
      const response = await validationService.getValidationOffersForGivenValidationAccounts(
        facilityID, /* keep facility ID. query will handle FG */
        contractHolderIDs
      );

      setValidationOffers(response.data);
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Failed to retrieve validation offers", {
        variant: "error",
        tag: "FailedToRetrieveValidationOffers"
      });
    }
  }, [JSON.stringify(contractHolderIDs), facilityID]);

  const resetValue = (searchType) => {
    setClearInputField(true);
    setSearchType(searchType);
  };

  return (
    <Card className={className}>
      <CardHeader className={classes.cardHeader} title="Ticket Validation" />
      <CardContent className={clsx(["ticket-val-body", classes.contentRoot])}>
        <Grid item xs={6}>
          <CodeReader
            handleRead={handleQRCodeRead}
            handleError={err => {}}
            isScanning={isScanning}
            disabled={isSendApplyValidationRunning && automaticallyApplyValidationEnabled}
            enableQROnly={true}
          />
        </Grid>
        <Grid item xs={6}>
          <ToggleButton
            classes={{
              root: classes.toggleButtonRoot,
              selected: classes.toggleButtonSelected
            }}
            selected={isScanning}
            onChange={() => {
              setIsScanning(prev => !prev);
            }}
            data-testid="toggle-button"
          >
            {isScanning ? "Stop Scanning" : "Scan Ticket"}
          </ToggleButton>
        </Grid>
        <Grid item xs={12} sm={8} className={classes.chipGrid}>
          <SelectableChip
            text="Ticket"
            className={clsx(["type-option", "ticket"])}
            variant="primary"
            onClick={() => resetValue(TICKET_SEARCH_TYPE.TICKET)}
            selected={searchType === TICKET_SEARCH_TYPE.TICKET}
          />
          {isLPREnabled && (<SelectableChip
            text="License Plate"
            className={clsx(["type-option", "licensePlate"])}
            variant="primary"
            onClick={() => resetValue(TICKET_SEARCH_TYPE.LICENSE_PLATE)}
            selected={searchType === TICKET_SEARCH_TYPE.LICENSE_PLATE}
          />
          )}
        </Grid>
        {validateTicketsPermission && (
          <Grid item xs={12}>
            <TicketValidations
              facilityID={facilityID}
              searchTermInput={searchInput}
              validationOffers={validationOffers}
              autoApplyValidationOffer={autoApplyValidationOffer}
              itemLimit={50}              
              isSendApplyValidationRunning={isSendApplyValidationRunning}
              handleSendApplyValidationRunningChange={onSendApplyValidationRunningChange}
              data-testid="ticket-validations"
              scopeAwareFacilityID={scopeAwareFacilityID}
              automaticallyApplyValidationEnabled={automaticallyApplyValidationEnabled}
              searchType={searchType}
              clearInputField={clearInputField}
              parsedFromCamera={parsedFromCamera}
              handleParsedFromCameraChange={onParsedFromCameraChange}
            />
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

ApplyValidationsPanel.defaultProps = {
  onDelete: () => {},
  onChange: () => {},
  contractHolderIDs: []
};

ApplyValidationsPanel.propTypes = {
  facilityID: PropTypes.string,
  onDelete: PropTypes.func,
  onChange: PropTypes.func,
  contractHolderIDs: PropTypes.arrayOf(PropTypes.number),
  scopeAwareFacilityID: PropTypes.string
};

export default ApplyValidationsPanel;
